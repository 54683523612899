<template>
  <h1>Home</h1>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
};
</script>
